body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  margin-left: 30px;
  margin-bottom: 10px;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 10px 10px !important;
}

h1, h2, h3, h4, h5 {
  margin: 20px 5px;
}

a {
  text-decoration: underline !important;
  display: block;
}


